* {
    font-family: "Inter";
  }

.cupertino-pane-wrapper {
  top: 40px;
}

.cupertino-pane-wrapper .move {
  width: 120px;
  height: 4px;
  background: #B5B7C6;
  opacity: 0.25;
  border-radius: 4px;
}

.cupertino-pane-wrapper .draggable {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #ffffff;
}

.cupertino-pane-wrapper .pane {
  overflow: hidden;
  max-width: 100%;
}

.react-tel-input {
  border: 1px solid #F4F4F9;
  background: #F4F4F9;
  border-radius: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 6px 20px;
  margin-bottom: 8px;
  width: 100% !important;
  position: relative;
  color: #000000;
  min-height: 43px;
}

.react-tel-input .flag-dropdown {
  border: none;
  border-radius: 12px;
  top: 4px;
}

.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .flag-dropdown.open,
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: #f5f5f5;
}

.react-tel-input .form-control {
  background: #F4F4F9;
  width: 100%;
  border: none;
}

.above .react-tel-input .country-list {
  bottom: 34px;
}

.alert-full-option .select-interface-option {
  display: contents;
}
